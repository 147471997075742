import axios from "axios";
import { baseUrl } from "./consts";

export const getUnreadReportsCount = (creator_id) => {
  return async (dispatch) => {
    dispatch({
      type: "SHOW_MAIN_LOADER",
    });
    await axios
      .get(`${baseUrl}/domain/ticket?creator_status=0&creator_id=${creator_id}`)
      .then((report) => {
        dispatch({
          type: "GET_UNREAD_REPORTS_COUNT",
          report,
        });
      })
      .finally(() => {
        dispatch({
          type: "HIDE_MAIN_LOADER",
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const getCurrentReport = (id) => {
  return async (dispatch) => {
    dispatch({
      type: "SHOW_MAIN_LOADER",
    });
    await axios
      .get(`${baseUrl}/domain/ticket/${id}`)
      .then((report) => {
        dispatch({
          type: "GET_CURRENT_REPORT",
          report,
        });
      })
      .finally(() => {
        dispatch({
          type: "HIDE_MAIN_LOADER",
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const putCurrentReport = (id, data) => {
  return async (dispatch) => {
    dispatch({
      type: "SHOW_MAIN_LOADER",
    });
    await axios
      .put(`${baseUrl}/domain/ticket/creator/${id}`, data)
      .then((report) => {
        dispatch(getUnreadReportsCount(id));
        dispatch({
          type: "PUT_CURRENT_REPORT",
          report,
        });
      })
      .finally(() => {
        dispatch({
          type: "HIDE_MAIN_LOADER",
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const getAllChannelReports = (arg) => {
  return async (dispatch) => {
    dispatch({
      type: "SHOW_MAIN_LOADER",
    });
    await axios
      .get(`${baseUrl}/domain/ticket`, {
        params: { ...arg },
      })
      .then((report) => {
        dispatch({
          type: "GET_ALL_CHANNEL_REPORTS",
          report,
        });
      })
      .finally(() => {
        dispatch({
          type: "HIDE_MAIN_LOADER",
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const getAllVideoReports = (arg) => {
  return async (dispatch) => {
    dispatch({
      type: "SHOW_MAIN_LOADER",
    });
    await axios
      .get(`${baseUrl}/domain/ticket`, {
        params: { ...arg },
      })
      .then((report) => {
        dispatch({
          type: "GET_ALL_VIDEO_REPORTS",
          report,
        });
      })
      .finally(() => {
        dispatch({
          type: "HIDE_MAIN_LOADER",
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const getAllCommentReports = (arg) => {
  return async (dispatch) => {
    dispatch({
      type: "SHOW_MAIN_LOADER",
    });
    await axios
      .get(`${baseUrl}/domain/ticket`, {
        params: { ...arg },
      })
      .then((report) => {
        dispatch({
          type: "GET_ALL_COMMENT_REPORTS",
          report,
        });
      })
      .finally(() => {
        dispatch({
          type: "HIDE_MAIN_LOADER",
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const getChannelReportsTotal = (arg) => {
  return async (dispatch) => {
    dispatch({
      type: "SHOW_MAIN_LOADER",
    });
    await axios
      .get(`${baseUrl}/domain/ticket`, {
        params: { offst: 0, limit: 1, ticket_type: 1, ...arg },
      })
      .then((report) => {
        dispatch({
          type: "GET_CHANNEL_REPORTS_TOTAL",
          report,
        });
      })
      .finally(() => {
        dispatch({
          type: "HIDE_MAIN_LOADER",
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
};
export const getVideoReportsTotal = (arg) => {
  return async (dispatch) => {
    dispatch({
      type: "SHOW_MAIN_LOADER",
    });
    await axios
      .get(`${baseUrl}/domain/ticket`, {
        params: { offst: 0, limit: 1, ticket_type: 2, ...arg },
      })
      .then((report) => {
        dispatch({
          type: "GET_VIDEO_REPORTS_TOTAL",
          report,
        });
      })
      .finally(() => {
        dispatch({
          type: "HIDE_MAIN_LOADER",
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const getCommentReportsTotal = (arg) => {
  return async (dispatch) => {
    dispatch({
      type: "SHOW_MAIN_LOADER",
    });
    await axios
      .get(`${baseUrl}/domain/ticket`, {
        params: { offst: 0, limit: 1, ticket_type: 3, ...arg },
      })
      .then((report) => {
        dispatch({
          type: "GET_COMMENT_REPORTS_TOTAL",
          report,
        });
      })
      .finally(() => {
        dispatch({
          type: "HIDE_MAIN_LOADER",
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
};
